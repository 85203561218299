import React from "react";
import * as Lib from "../style/lib";
import * as OM from "../style/about-style";
import { TheTeam } from "../utils/team";
import { Location } from "../pages/location"
import actiway from "../../assets/payment/actiway-1.png";
import benify from "../../assets/payment/benify-1.png";
import epassi from "../../assets/payment/epassi-1.png";
import swish from "../../assets/payment/swish-1.png";
import wellnet from "../../assets/payment/wellnet-1.png";

export const OmOss: React.FC = () => {
  return (
    <OM.AboutContainer>
      <OM.TeamImgWithOverlay />

      <OM.AboutIntroContainer>
        <Lib.Header2 $textAlign="center">
          Haninges mest erfarna naprapater
        </Lib.Header2>
        <Lib.BreadParagraph $textAlign="center" $DmaxWidth="770px">
          Välkommen till oss på Haninge Naprapat AB – din naprapat i Haninge sedan 1984.
          Vi erbjuder både naprapati och massage, så till oss kommer du för få hjälp med ditt
          smärttillstånd eller för en välgörande massage.
          <br /> <br />
          Vi behandlar och hjälper dig med besvär som nackspärr, spänningshuvudvärk, ryggskott,
          ischias, tennisarmbåge, hälsporre eller hopparknä.
        </Lib.BreadParagraph>

        <Lib.Header5 $marginBottom="68px">
          Välkommen!
        </Lib.Header5>
      </OM.AboutIntroContainer>

      <OM.TeamStandup>
        <Lib.Header3>Vi som arbetar här</Lib.Header3>

        <OM.TeamGrid>
          {TheTeam.map((member) => (
            <OM.TeamCard
              key={member.email}
              name={member.name}
              education={member.education}
              text={member.text}
              img={member.img}
              email={member.email}
            />
          ))}
        </OM.TeamGrid>
      </OM.TeamStandup>

      <Location />

      <Lib.Header2 $textAlign="center">
        Hos oss kan du betala med
      </Lib.Header2>

      <OM.FriskvardContainer>
        <OM.LogoWrapper>
          <OM.LpCaseLogo
            src={actiway}
            alt="Hos oss kan du betala via Actiway" />
        </OM.LogoWrapper>

        <OM.LogoWrapper>
          <OM.LpCaseLogo
            src={benify}
            alt="Hos oss kan du betala via Benify" />
        </OM.LogoWrapper>

        <OM.LogoWrapper>
          <OM.LpCaseLogo
            src={epassi}
            alt="Hos oss kan du betala via Epassi" />
        </OM.LogoWrapper>

        <OM.LogoWrapper>
          <OM.LpCaseLogo
            src={swish}
            alt="Hos oss kan du betala via Swish" />
        </OM.LogoWrapper>

        <OM.LogoWrapper>
          <OM.LpCaseLogo
            src={wellnet}
            alt="Hos oss kan du betala via Wellnet" />
        </OM.LogoWrapper>
      </OM.FriskvardContainer>

    </OM.AboutContainer>
  );
};
